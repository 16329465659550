import React from "react";
import Footer from "../Footer/Footer";
import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  const data = [
    {
      heading: "INFORMATION COLLECTED",
      para: "We collect personal information from you in different services we provide when you use our site, tekkregg.com, and (Mobile Application) link. If you register and purchase an online shopping cart and other services from tekkreggs, we request personal distinct information which includes full name, email address, shipping address, contact and alternative number, and financial information such as credit card number and expiration date, CVV numbers",
    },

    {
      heading: "INFORMATION USE",
      para: "We may use the information we collect from you to have an efficient way of processing your transactions. It will permit us to render excellent service in responding to any customer’s request and to ask for reviews, ratings, and feedback on our services and products. In cases where we have trouble processing your order or have some difficulties in locating your exact location and other several reasons to communicate, we will use your personal distinct information to contact you. And most probably we may use your email and phone number to send you updates, offers, and other site/ mobile application features.",
    },

    {
      heading: "LOG DATA",
      para: "Like many operators, we gather certain information and store it in log files when you interact with our website, mobile application, and services. This Log Data information may include information such as your computer's/mobile’s Internet Protocol ('IP') address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, identification numbers associated with your devices, your mobile carrier, system configuration information, the time spent on those pages and other statistics.",
    },

    {
      heading: "COOKIES",
      para: "Cookies are small, often encrypted, text files that are stored on your computer when you visit certain web pages and install mobile applications. These are sent to your browser from the website that you visit and are stored on your computer's hard drive and mobile storage. At tekkreggs, we use cookies on our site to keep track of what items you have in your basket and to remember you when you return to our site. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our Service.",
    },

    {
      heading: "SECURITY",
      para: "We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. The security of your personal information is very important to us. All sensitive information such as credit card numbers is encrypted by using secure socket layer technology. We observe generally accepted standards to protect the personal information submitted to us both during transmission and once we receive it. But no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
    },

    {
      heading: "CHANGES TO THIS PRIVACY NOTICE",
      para: "We may change or update our Privacy Policy from time to time in the future. These changes will be posted here. We advised you to review and check back regularly for any amendments. After such changes take effect, you agree to be legally bound and to abide by the revised Privacy Policy and Terms of Use, of which this Policy is a part.",
    },

    {
      heading: "Clarification Needed from Tech",
      para: "What is the personal information you are collecting, and where are we using the information in detailed documentation Mode of payment in Dubai Payment gateway using terms and conditions What order working model details They are no COD involved.",
    },
  ];

  return (
    <div>
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Privacy policy</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p>
              Tekkreggs is committed to providing a quality service to you and
              held high esteem in your concern about protecting your privacy and
              personal data. Your privacy is vital to us. We highly value your
              trust and confidence in us and we want to assure you that all your
              personal information is kept completely confidential.
            </p>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            {data.map((d) => (
              <div className={styles.privacypolicyh2divpart1div22}>
                <div className={styles.privacypolicyh2divpart1divp1}>
                  <h2>{d.heading}</h2>
                  <p>{d.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer style={{ backgroundColor: "#2d2d2d" }} />
    </div>
  );
}
