import "./App.css";
import HomePage from "./Components/Home/HomePage/HomePage";
import Services from "./Components/Services/Services";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import AnimatedRoutes from "./Components/AnimatedRoutes";
import NavBar from "./Components/NavBar/NavBar";

function App() {
  return (
    <div>
      <BrowserRouter>
        <AnimatedRoutes />
        {/* <Routes >
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
        </Routes> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
