import React from "react";
import Footer from "../Footer/Footer";
import styles from "./TermsAndCondition.module.css";

export default function TermsAndCondition() {
  return (
    <div>
      {/* <NavBar /> */}
      <div className={styles.privacypolicy}>
        <div className={styles.privacypolicy1}>
          <div className={styles.privacypolicyh2div}>
            <h2>Terms and Conditions</h2>
          </div>
          <div className={styles.privacypolicyh2divp}>
            <p className={styles.preamble}>Preamble</p>
            <p>
              Welcome to tekkreggs.com, this website (the “Website”) is provided
              by Majid Al Futtaim Hypermarkets LLC (“We” or “US”) as a service
              to our customers.
            </p>
            <p>
              Please note that your use of the Website service constitutes your
              unconditional agreement to follow and be bound by these terms and
              conditions (the “Terms and Conditions”).
            </p>
            <p>
              We reserve the right to change the Terms and Conditions at any
              time. Any such changes will take effect when posted on the
              Website.
            </p>
            <p>
              It is your responsibility to read the Terms and Conditions on each
              occasion you use this Website and your continued use of the
              Website shall signify your acceptance to be bound by the latest
              Terms and Conditions. If you do not agree to (or cannot comply
              with) any of the Terms and Conditions, do not use this Website.
            </p>
            <p>
              All the Information (as defined below) provided must be accurate.
              Providing any inaccurate Information constitutes a breach of these
              Terms and Conditions. By confirming your purchase at the end of
              the checkout process, you agree to accept and pay for the item(s)
              requested.
            </p>
            <p>
              If you are not a consumer, you confirm that you have authority to
              bind any business.
            </p>
          </div>

          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Cancellation</h2>
                <p>
                  Please note that there may be certain orders that we may
                  reject or cancel. We reserve the right, at our sole
                  discretion, to refuse or cancel any order for any reason.
                </p>
                <p>
                  For your convenience, you will not be charged until your
                  payment method is authorized; the order information is
                  verified for accuracy.
                </p>
                <p>
                  Some situations that may result in your order being cancelled
                  due to limitations on quantities available for purchase,
                  inaccuracies or errors in product or pricing information, or
                  problems identified by our relevant department.
                </p>
                <p>
                  We may also require additional verifications or information
                  before accepting any order. We will contact you if all or any
                  portion of your order is cancelled or if additional
                  information is required to accept your order. If your order is
                  cancelled after your credit card has been charged, we will
                  issue a credit to your credit card in the amount of the
                  charge.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Website Security</h2>
                <p>
                  You are prohibited from violating the security of the Website,
                  including, without limitation,
                </p>
                <p>
                  (a) accessing data not intended for such user or logging onto
                  a server or an account which the user is not authorized to
                  access;
                </p>
                <p>
                  (b) attempting to probe, scan or test the exposure of a system
                  or network or to breach security or authentication measures
                  without proper authorization;
                </p>
                <p>
                  (c) attempting to interfere with service to any user, host, or
                  network, including, without limitation, via means of
                  submitting a virus to the Website, overloading, flooding,
                  spamming, mail bombing or crashing;
                </p>
                <p>
                  (d) sending unsolicited email, including promotions and/or
                  advertising of products or services.
                </p>
                <p>
                  Violations of system or network security may result in civil
                  or criminal liability. We will investigate such violations and
                  prosecute users who are involved in such violations. You agree
                  not to use any device, software or routine to interfere or
                  attempt to interfere with the proper working of this Website
                  or any activity being conducted on this Website. You agree,
                  further, not to use or attempt to use any engine, software,
                  tool, agent or other device or mechanism (including without
                  limitation browsers, spiders, robots, avatars or intelligent
                  agents) to navigate or search this Website other than the
                  search engine and search agents available from us on this
                  Website.
                </p>
                <p>
                  Access to the Website may be occasionally restricted to allow
                  for repair, maintenance or the introduction of new facilities
                  or services.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Terms of Use</h2>
                <p>
                  The Website can be used only by persons who can form legally
                  binding contracts under applicable law. Persons who are
                  incompetent to contract are not eligible to use the Website.
                  If your age is below that of 18 years your parents or legal
                  guardians can transact on behalf of you if they are registered
                  users/members. We reserve the right to terminate your
                  registration and refuse to provide you with access to the
                  Website if We discovers that you are under the age of 18
                  years.
                </p>
                <p>
                  The Website cannot be accessed to persons whose registration
                  has been suspended or terminated by us for any reason
                  whatsoever. Those who choose to access this Website from
                  outside the United Arab Emirates are responsible for
                  compliance with local laws if and to the extent local laws are
                  applicable. We will deliver the products only within the UAE
                  and will not be liable for any claims relating to any products
                  ordered from outside its jurisdiction.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Registration Process</h2>
                <p>
                  The information (the “Information”) provided from your end
                  will be protected in accordance with our Privacy Policy. If
                  you use the Website, you are responsible for maintaining the
                  confidentiality of your account and password and for
                  restricting access to your computer, and you agree to accept
                  responsibility for all activities that occur under your
                  account or password.
                </p>
                <p>
                  We assume no liability to any person for any loss or damage
                  which may arise as a result of any failure by you in
                  protecting your password or account. Any suspicious activities
                  on your account shall be notified to us by contacting us
                  immediately through the address provided below. If we have
                  reason to believe that there is likely to be a breach of
                  security or misuse of the Website, we may require you to
                  change your password or we may suspend your account without
                  any liability to us.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Product's Pricing</h2>
                <p>
                  Errors may occur in the process of providing product and
                  pricing information by the Website. We cannot confirm the
                  price of a product except after ordering. In the event that a
                  product is listed at an incorrect price or with incorrect
                  information due to an error in pricing or product information,
                  We shall have the right, at our sole discretion, to either
                  contact you for instructions or cancel your order and notify
                  you of such cancellation.
                </p>
                <p>
                  In the event that the Website accepts your order, the same
                  shall be debited to your credit card account and duly notified
                  to you by email. The payment may be processed prior to our
                  dispatch of the product that you have ordered. If we have to
                  cancel the order after we have processed the payment, the said
                  amount will be reversed back to your credit/debit card
                  account. No cash disbursement shall be made under any
                  condition whatsoever.
                </p>
                <p>
                  Sometimes a price online does not match the price in a store.
                  In our effort to maintain the fact of being the lowest price
                  provider in the United Arab Emirates, store pricing will
                  sometimes differ from online prices. Prices and availability
                  are subject to change without any prior notice. Further,
                  description/performance of products stated against each are
                  based on the catalogue and technical literature printed by the
                  manufacturers/agent. Therefore, the write-up provided against
                  each product is not that of ours and subject to change without
                  prior notice.
                </p>
                <p>
                  All customers will be charged in UAE Dirham (PayPal in USD)
                  and differences between approximation in any foreign currency
                  and actual billing price on the customer's credit card bill is
                  probably attributable to different credit card companies
                  charging different exchange rates.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Redemption of tekkreggs coupons(Where Applicable)</h2>
                <p>
                  In the event that a customer is allowed to redeem a
                  Tekkeggs.com coupon through the Website, that coupon will be
                  redeemed for full amount only, and no partial redemption is
                  allowed.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Order Acceptance</h2>
                <p>
                  We reserve the right, at its sole discretion, to refuse or
                  cancel any order for any reason whatsoever. Limitations on
                  quantities available for purchase inaccuracies or errors in
                  product or pricing information, or problems identified by our
                  credit and fraud are some situations that may result in the
                  order being cancelled, additional verifications or information
                  may be requested before accepting any order. We will contact
                  you if all or any portion of your order is cancelled or if
                  additional information is required to accept your order. If
                  your order is cancelled after your credit/debit card has been
                  charged, the said amount will be reversed back in your
                  credit/debit card account. No cash disbursement shall be made
                  under any condition whatsoever.
                </p>
                <p>
                  Once the order leaves our logistics center, you will be
                  notified by sms with your shipment tracking number.
                </p>
                <p>
                  It is the responsibility of the customer to ensure that they
                  are present at time of delivery.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Cancellation by the Customer</h2>
                <p>
                  If a cancellation notice from your side is duly received by us
                  and the order has not been processed/approved by us, we shall
                  cancel the order and refund the entire amount to your
                  credit/debit card.
                </p>
                <p>
                  Orders that are cancelled after they have been shipped are
                  subject to a AED 40 cancellation fee to be deducted from your
                  refund for shipping and processing. We have the full right to
                  demonstrate whether an order has been shipped or not. The
                  customer agrees not to dispute the decision made by us and
                  accept our decision regarding the cancellation.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Quantity Limits and Dealer Sales</h2>
                <p>
                  We reserve the right, at our sole discretion, to limit the
                  quantity of items purchased per person, per household or per
                  order. The said limitations may be applicable to orders placed
                  by the same account, the same credit card, and also to orders
                  that use the same billing and/or shipping address. We will
                  provide notification to the customer should such limits be
                  applied. We also reserve the right, at its sole discretion, to
                  prohibit sales to dealers or resellers. For purposes of these
                  Terms and Conditions, reselling shall be defined as purchasing
                  or intending to purchase any product(s) from Tekkreggs.comfor
                  the purpose of engaging in a commercial sale of that same
                  product(s) with a third party.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Credit/Debit Card Information</h2>
                <p>
                  We reserve the right to refuse to accept the Credit/Debit Card
                  or, in such case the transaction is accepted, cancel the
                  transaction, at our sole discretion, as part of our own fraud
                  detection program.
                </p>
                <p>
                  The Credit/Debit card details provided by you for benefiting
                  from the services on the Website will be truthful, valid and
                  accurate and you shall use the Credit/Debit card which is
                  lawfully owned by you, i.e. in a Credit/Debit card
                  transaction, you must use your own Credit/Debit card. The said
                  information will not be utilized and shared by us with any of
                  the third parties unless required for fraud verifications or
                  by law, regulation or court order. We will not be liable for
                  any Credit/Debit card fraud. The liability for use of a card
                  fraudulently will be on you and the responsibility to prove
                  otherwise shall be exclusively on you.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Unlawful Transaction</h2>
                <p>
                  We reserve the right to recover the cost of products,
                  collection charges and lawyers’ fees from persons using the
                  Website fraudulently. We reserve the right to initiate legal
                  proceedings against such persons for fraudulent use of the
                  Website and any other unlawful acts or acts or omissions in
                  breach of these Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>E-Communication</h2>
                <p>
                  When you visit the Website or send emails to the Website, you
                  are communicating with us electronically. You consent to
                  receive communications from us electronically. We will
                  communicate with you by email or by posting notices on the
                  Website. You agree that all agreements, notices, disclosures
                  and other communications that we provide to you electronically
                  satisfy any legal requirement that such communications be in
                  writing.
                </p>
                <p>As a customer, you agree and confirm on the following:</p>
                <p>
                  1. There will be optional extended warranty on products sold
                  by us. Should there be any problems with the product, it is
                  the choice of the customer to contact the manufacturer's local
                  agent or any other trained person to repair the product or any
                  Tekkreggsfour hypermarket branches in the UAE. All products
                  shipped by us have gone through our own quality control
                  checkpoints and are deemed to be functional when shipped.
                </p>
                <p>
                  2. All electronic items shipped are designed to work on
                  220-240V. If you do not have facilities to operate on this
                  electricity voltage, then use of the equipment is at your own
                  risk. We will not accept back any products that have been
                  damaged due to use with faulty electrical sources. Please
                  check the owners’ manual before use to see if your product
                  meets the local requirements required for safe operation.
                </p>
                <p>
                  3. In the event of a non-delivery occurs on account of a
                  mistake by you (i.e. wrong name or address or any other wrong
                  information), any extra cost incurred by us for redelivery
                  shall be claimed from you.All orders are shipped out by
                  courier or supplier deliveries. Delivery is normally
                  door-to-door but there may be instances where this is not
                  possible due to courier company limitations or local laws.
                </p>
                <p>
                  4. You will provide correct information requested from your
                  side. We reserve the right to confirm and validate the
                  information and other details provided by you at any point of
                  time. If upon confirmation your details are found not to be
                  true, we have the right, in our sole discretion to reject the
                  registration and ban you from using the services of the
                  Website.
                </p>
                <p>
                  5. That you are transacting through this Website at your sole
                  risk.
                </p>
                <p>
                  6. That correct and proper address at which delivery of the
                  product ordered by you shall be provided by you. If a
                  recipient's address is found to be incomplete or incorrect, we
                  may attempt to find the correct address and to complete the
                  delivery, but we assume no responsibility for our inability to
                  complete delivery under such circumstances. If the address is
                  incorrect, or if delivery can't be completed, then a surcharge
                  for extra cost will be imposed to the customer.
                </p>
                <p>
                  7. That before placing an order you will check the product
                  description carefully. By placing an order for a product you
                  agree to be bound by the conditions of sale included in the
                  item's description.
                </p>
                <p>Display of Products</p>
                <p>
                  The colors of our products that appear on the Website will be
                  displayed as accurately as possible. However, as the actual
                  colors you see will depend on your monitor, we cannot
                  guarantee that your monitor's display of any color will be
                  accurate.
                </p>
                <p>Delivery Costs</p>
                <p>Shipping costs will be calculated as followed:</p>
                <p>Weight and size of the product.</p>
                <p>Shipment scheme.</p>
                <p>
                  The location you are shipping to (also called the destination)
                  all orders are shipped out by courier or supplier deliveries.
                  Delivery is normally door-to-door but there may be instances
                  where this is not possible due to courier company limitations
                  or local laws.
                </p>
                <p>
                  Delivery fees for food items and non-food items will both be
                  applicable for one order if both types of items are included
                  in that order.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Amendment of the Terms and Conditions</h2>
                <p>
                  We may at any time modify the Terms and Conditions of the
                  Website without any prior notification to you. You can access
                  the latest version of the Terms and Conditions at any given
                  time on the Website. In the event the modified Terms and
                  Conditions are not acceptable to you, you should discontinue
                  using the service of the Website. However, if you continue to
                  use the service you shall be deemed to have agreed to accept
                  and abide by the modified Terms & Conditions of this Website.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Governing Law and Jurisdiction</h2>
                <p>
                  The Terms and Conditions shall be construed in accordance with
                  the federal laws of the United Arab Emirates and the local
                  laws and regulations of the Emirate of Dubai. The Courts at
                  Dubai shall have exclusive jurisdiction in any proceedings
                  arising out of the Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Copyright & Trademark</h2>
                <p>
                  Unless otherwise noted, all contents of the Website are
                  copyrights, trademarks, trade dress and/or other intellectual
                  property owned, controlled or licensed by Majid Al Futtaim
                  Hypermarkets LLC/Majid Al Futtaim Retail LLC, or one of its
                  affiliates or by third parties who have licensed their
                  materials to us and are protected by the applicable laws.
                </p>
                <p>
                  We and our suppliers and licensors expressly reserve all
                  intellectual property rights in all text, programs, products,
                  processes, technology, content and other materials, which
                  appear on this Website. Access to this Website does not confer
                  and shall not be considered as conferring upon anyone any
                  license under any of our or any third party's intellectual
                  property rights. All rights, including copyright, in this
                  Website are owned by or licensed to us/our affiliates. Any use
                  of this Website or its contents, including copying or storing
                  it or them in whole or part, other than for your own personal,
                  non-commercial use is prohibited without our permission. You
                  may not modify, distribute or re-post anything on this Website
                  for any purpose.
                </p>
                <p>
                  Our names and logos and all related product and service names,
                  design marks and slogans are the trademarks or service marks
                  of Majid Al Futtaim Hypermarkets LLC/Majid Al Futtaim Retail
                  LLC. No trademark or service mark license is granted in
                  connection with the materials contained on this Website.
                  Access to this Website does not authorize anyone to use any
                  name, logo or mark in any manner whatsoever.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Objectionable Material</h2>
                <p>
                  You understand that by using this Website or any services
                  provided on the Website, you may encounter content that may be
                  deemed by some to be offensive, indecent, or objectionable,
                  which content may or may not be identified as such. You agree
                  to use the Website and any service at your sole risk and that
                  to the fullest extent permitted under applicable law, We and
                  our affiliates shall have no liability to you for content that
                  may be deemed offensive, indecent, or objectionable to you.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Indemnity</h2>
                <p>
                  You agree to defend, indemnify and hold us harmless and its
                  affiliates from and against any and all claims, damages, costs
                  and expenses, including attorneys' fees, arising from or
                  related to your use of the Website or any breach by you of
                  these Terms and Conditions and/or the applicable laws.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Termination</h2>
                <p>
                  These Terms and Conditions is effective unless and until
                  terminated by either you or us. You may terminate these Terms
                  and Conditions at any time, provided that you discontinue any
                  further use of this Website. We may terminate these Terms and
                  Conditions at any time and may do so immediately without
                  notice, and accordingly deny your access to the Website. Such
                  termination will be without any liability to us. Upon any
                  termination of these Terms and Conditions by either you or us
                  you must promptly destroy all materials downloaded or
                  otherwise obtained from this Website, as well as all copies of
                  such materials, whether made under these Terms and Conditions
                  or otherwise. Our right to any feedback shall survive any
                  termination of these Terms and Conditions. Any such
                  termination of these Terms and Conditions shall not cancel
                  your obligation to pay for the product already ordered from
                  the Website or affect any liability that may have arisen under
                  these Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Limitations of Liability</h2>
                <p>
                  Majid Al Futtaim Hypermarkets LLC or any of its affiliates,
                  employees, directors, agents or suppliers shall not be liable
                  to you or to any other person for any indirect, special,
                  incidental or consequential losses or damages of any nature
                  arising out of or in connection with the use of or inability
                  to use the Website, including without limitation damages for
                  loss of profit, accuracy of results, loss of data, work
                  stoppage, or computer failure. Without prejudice to the
                  generality of the section above, our total liability to you
                  for all liabilities arising out of these Terms and Conditions
                  is it in tort or contract is limited to the value of the
                  product ordered by you.{" "}
                </p>
                <p>
                  We make no representations or warranties about the accuracy,
                  reliability, completeness, correctness and/or timeliness of
                  any content, information, software, text, graphics, links or
                  communications provided on or through the use of the Website
                  or that the operation of the Website will be error free and/or
                  uninterrupted. Consequently, we assume no liability whatsoever
                  for any monetary or other damage suffered by you on account of
                  the delay, failure, interruption, or corruption of any data or
                  other information transmitted in connection with use of the
                  Website; and/or any interruption or errors in the operation of
                  the Website.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Disclaimer</h2>
                <p>
                  Though we make sure that the delivery of purchased products
                  shall be delivered on time. No claim, penalties, refunds or
                  part refund against us in case of delays in delivery or error
                  in delivery. Although We aim to offer you the best service
                  possible, If a fault occurs in our services you should report
                  it immediately to the Customer Services by phone at 800 7 3232
                  and we will attempt to correct the fault within reasonable
                  time.
                </p>
                <p>
                  The product details, information, ingredients, nutritional
                  guides and dietary/allergy advice may change from time to
                  time.
                </p>
                <p>
                  Therefore, we highly recommend that you read the label on the
                  products you purchase and not rely on information provided on
                  the website.
                </p>
                <p>
                  Majid Al Futtaim Hypermarkets LLC does not represent or
                  warrant that the information contained in this website is
                  accurate, complete and up-to-date. We are not be responsible
                  or liable for any damages of any kind whatsoever arising out
                  of, caused by or related to your use of this website.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Entire Agreement</h2>
                <p>
                  If any part of these Terms and Conditions is determined to be
                  invalid or unenforceable pursuant to applicable law including,
                  but not limited to, the warranty disclaimers and liability
                  limitations set forth above, then the invalid or unenforceable
                  provision will be deemed to be superseded by a valid,
                  enforceable provision that most closely matches the intent of
                  the original provision and the remainder of these Terms and
                  Conditions shall continue in effect. Unless otherwise
                  specified herein, these Terms and Conditions constitute the
                  entire agreement between you and us with respect to the
                  Website sites/services and it supersedes all prior or
                  contemporaneous communications and proposals, whether
                  electronic, oral or written, between you and us with respect
                  to the Website sites/services. Our failure to act with respect
                  to a breach by you or others does not waive its right to act
                  with respect to subsequent or similar breaches.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Privacy Policy</h2>
                <p>
                  We do not sell or rent your personal information to third
                  parties for their marketing purposes without your explicit
                  consent and we only use your information as described in the
                  Privacy Policy. We view protection of your privacy as a very
                  important community principle. We understand clearly that you
                  and the Information is one of our most important assets. We
                  store and process the Information on computers that are
                  protected by physical as well as technological security
                  devices. We use third parties to verify and certify our
                  privacy principles.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Tekkreggs.com Refund Policy</h2>
                <p>
                  a. The decision on refund will vary on case to case basis and
                  there will be certain criteria’s for that:
                </p>
                <p>Dead on arrival.</p>
                <p>
                  Unable to procure products: a reasonable time of 7 – 10 days
                  from the date of authorization has to be given Unable to
                  repair the product.
                </p>
                <p>Lost in deliveries</p>
                <p>
                  b. If the product is not available then a full refund will be
                  made to the customer. The refund will be made in next billing
                  cycle.
                </p>
                <p>
                  c. Refund is allowed only once duly approved by after sales
                  service team of Majid Al Futtaim Hypermarkets LLC.
                </p>
                <p>
                  d. The product refund is applicable in the case product is
                  malfunctioning and not repairable.
                </p>
                <p>
                  e. Majid Al Futtaim Hypermarkets LLC will affect the refund on
                  receiving the unit back.
                </p>
                <p>
                  f. The product will be refunded at the same price as it was
                  sold to you.{" "}
                </p>
                <p>
                  g. The settlement of the refund will only be affected once the
                  bank has refunded the money which is next billing cycle from
                  the date of application of refund.
                </p>
                <p>
                  h. Refund will be made through the credit/debit card only for
                  Online Credit Card Payment.
                </p>
                <p>
                  i. Refund will be made ONLY at store level for Cash on
                  Delivery.
                </p>
                <p>
                  j. Refund will be made through the PayPal account for PayPal
                  payment.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.privacypolicyh2divpart1div}>
            <div className={styles.privacypolicyh2divpart1div22}>
              <div className={styles.privacypolicyh2divpart1divp1}>
                <h2>Refund Conditions:</h2>
                <p>
                  1. The After Sales Service Office is the sole reference for
                  any claim of exchange, refund or repair.
                </p>
                <p>
                  2. Any items to be exchanged, refunded or repaired must be
                  presented with the Order Confirmation and the Original
                  Delivery Note as proof of purchase. No exchange, refund or
                  repair will be considered without it.3. Any items to be
                  exchanged, refunded or repaired may be returned in any
                  Hypermarket in the UAE .Notwithstanding the terms and
                  conditions herein, items shall be exchanged, refunded or
                  repaired at the sole discretion of Majid Al Futtaim
                  Hypermarkets LLC.
                </p>
                <p>Exchange or Refund</p>
                <p>
                  1. Items to be exchanged or refunded must be presented with
                  their complete box “as new” with inside packing, and all
                  accessories sealed (i.e. in re-sellable condition).
                </p>
                <p>
                  2. Items may only be exchanged or refunded if they are
                  returned within 7 days from purchase or delivery date.
                </p>
                <p>
                  3. Mobile phones, laptop computers, electronic tables,
                  hygienic items (such as beard or hair care items), consumable
                  items (such as DVDs, CDS, tapes), watches and jewelry are not
                  subject to any exchange or refund, unless they are found
                  defective and returned within 7 days from the purchase or
                  delivery date. For the avoidance of doubt, Majid Al Futtaim
                  Hypermarkets LLC shall, at its sole discretion, have the final
                  say on whether the items are truly found defective.
                </p>
                <p>
                  4. Refunds shall be made in the same manner in which the
                  purchase of the item was made. For the avoidance of doubt,
                  items that were purchased in cash shall be refunded in cash
                  while items purchased by credit/debit card shall be refunded
                  to the same credit/debit card.
                </p>
                <p>
                  5. Home delivery items may only be refunded if the event of
                  non-delivery, and upon the supplier’s written confirmation of
                  such non-delivery.
                </p>
                <p>
                  6. Installed items are not subject to any exchange or refund.
                </p>
                <p>Repairs</p>
                <p>
                  1. Items found defective after 7 days from purchase or
                  delivery date will be subject to the supplier’s terms and
                  conditions or warranty, if applicable.
                </p>
                <p>
                  2. Items to be repaired are at the sole discretion of Majid Al
                  Futtaim Hypermarkets LLC. For the avoidance of doubt, Majid Al
                  Futtaim Hypermarkets LLC may reasonably decide not the repair
                  the items.
                </p>
                <p>
                  3. Items may be repaired in no less than 30 days from the
                  return date. In the event that the items are not repaired
                  after 30 days from the return date, then the customer may
                  receive an exchange voucher for the amount paid subject to the
                  exchange conditions set out above. For the avoidance of doubt,
                  the item shall not be refunded.
                </p>
                <p>
                  4. Accessories (such as batteries, chargers, brushes) are not
                  subject to any repairs and are not under warranty.
                </p>
                <p>
                  5. Home delivery items (such as TVs, Home Theatres and Big
                  Appliances) are entitled to onsite maintenance subject to the
                  supplier’s services.
                </p>
                <p>Substitute:</p>
                <p>
                  When a product at the time of picking is out of stock,
                  Tekkreggs will choose an alternative. If the price of the new
                  product is higher, you will not be charged extra & if it's
                  less, you'll pay the smaller amount.
                </p>
                <p>
                  Please note that the "Allow Substitute" option is based on the
                  customer's consent during checkout.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ backgroundColor: "#2d2d2d" }} />
    </div>
  );
}
