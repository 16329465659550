import React from "react";
import styles from "./PassengerDrones.module.css";
import ourdrone2 from "../../assets/ourdrone2.png";
import Tilt from "react-parallax-tilt";

import passngr from "../../assets/passngr.json";
import Lottie from "react-lottie";
import Aos from "aos";
import "aos/dist/aos.css";

export default function PassengerDrones() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: passngr,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={styles.maindiv}>
      <div className={styles.maindiv2}>
        <Tilt
          glareEnable={true}
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          glareColor={"rgb(255,0,0)"}
        >
          <div className={styles.maindivimg}>
            <img src={ourdrone2} alt="passengersdrone" />
            <div className={styles.maindivimgshdw}></div>
            {/* <Lottie
              options={defaultOptions}
              // height={80}
              // width={80}
              loop={false}
              style={{ color: "red" }}
            /> */}
          </div>
        </Tilt>

        <div className={styles.maindivcontent}>
          <h2>Passenger Drones</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
          </p>
        </div>
      </div>
    </div>
  );
}
