import React from "react";
import styles from "./EfficentCargoPoints.module.css";

export default function EfficentCargoPoints() {
  return (
    <div className={styles.solrpowermain}>
      <div className={styles.solrpowerrr2}>
        <div className={styles.solrpower}>
          <div className={styles.solrpower2}>
            <div className={styles.powno}>
              <p>01</p>
            </div>
            <div className={styles.powh2}>
              <h2>Efficient</h2>
            </div>
            <div className={styles.powp}>
              <p>
                Designed to be lightweight with maximissed cargo space to
                minimise energy consumption and costs while maximising
                utilisation.
              </p>
            </div>
          </div>
          <div className={styles.solrpower2}>
            <div className={styles.powno}>
              <p>02</p>
            </div>
            <div className={styles.powh2}>
              <h2>Connected</h2>
            </div>
            <div className={styles.powp}>
              <p>
                Connected to help you keep track, maintain and optimise your
                fleet over time. A necessity for commercial vehicle fleets
                today.
              </p>
            </div>
          </div>
          <div className={styles.solrpower2}>
            <div className={styles.powno}>
              <p>03</p>
            </div>
            <div className={styles.powh2}>
              <h2>Solar-powered</h2>
            </div>
            <div className={styles.powp}>
              <p>
                Charged with the help of the sun, our vehicle can reach energy
                autonomy. Minimise your dependency on the grid by utilising
                solar panels on the roof.
              </p>
            </div>
          </div>
          <div className={styles.solrpower2}>
            <div className={styles.powno}>
              <p>04</p>
            </div>
            <div className={styles.powh2}>
              <h2>Modular</h2>
            </div>
            <div className={styles.powp}>
              <p>
                The cargo space is designed to be customised to diffrent
                delivery needs, from refrigerated goods to pallets to tools and
                moore.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
