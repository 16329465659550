import React from "react";
import styles from "./AboutUsPageParticals.module.css";
import ParticalBackground from "../../ParticalBackground/ParticalBackground";
import { motion } from "framer-motion";
import AboutUs from "../AboutUs/AboutUs";
import Footer from "../../Footer/Footer";

export default function AboutUsPageParticals() {
  return (
    <motion.div
      className={styles.pnvmain}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ParticalBackground></ParticalBackground>
      <div className={styles.prodtpage}>
        <AboutUs />
      </div>
      {/* <div className={styles.prodtpage}>
        <Footer />
      </div> */}
    </motion.div>
  );
}
