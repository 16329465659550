import React from "react";
import styles from "./Graph.module.css";

export default function Graph() {
  return (
    <div className={styles.graph}>
      <div className={styles.graph2}>
        <div className={styles.graph3}>
          <div className={styles.graph4}>
            <p>EVIG</p>
          </div>
          <div className={styles.graph41}>
            <p>CONVENTINAL ELECTRIC VEHICLE</p>
          </div>
        </div>
        <div className={styles.graphhldiv}>
          <div className={styles.graphhl}></div>
          <div className={styles.graphhlp}>1 year, 20,000 km</div>
        </div>
        <div className={styles.graph3}>
          <div className={styles.graph5}>
            <p>EVIG</p>
          </div>
          <div className={styles.graph51}>
            <p>CONVENTINAL ELECTRIC VEHICLE</p>
          </div>
        </div>
      </div>
    </div>
  );
}
