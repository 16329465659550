import React, { useEffect } from "react";
import Styles from "./Footer.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import logo from "../../assets/logoo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

function Footer(props) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const upup = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={Styles.footermain} style={props.style}>
      <div className={Styles.footermain1}>
        <div className={Styles.footermain2}>
          <img className={Styles.logo} src={logo} alt="ALT" />
        </div>
        <div className={Styles.footermain21}>
          <Link
            exact
            activeClassName={Styles.active}
            to="/"
            className={Styles.navlink}
            onClick={upup()}
          >
            Home
          </Link>
          <Link
            exact
            activeClassName={Styles.active}
            to="/aboutus"
            className={Styles.navlink}
            onClick={upup()}
          >
            About Us
          </Link>
          <Link
            exact
            activeClassName={Styles.active}
            to="/contactus"
            className={Styles.navlink}
            onClick={upup()}
          >
            Contact Us
          </Link>
        </div>
        {/* <div className={Styles.footermain22}>
          <Link
            exact
            activeClassName={Styles.active}
            to="/privacypolicy"
            className={Styles.navlink}
          >
            Privacy policy
          </Link>
          <Link
            exact
            activeClassName={Styles.active}
            to="/termsandconditions"
            className={Styles.navlink}
          >
            Terms and Condition
          </Link>
        </div> */}
        <div className={Styles.footermain23}>
          <div className={Styles.footermain23s}>
            <p>Social Links</p>
          </div>
          <div className={Styles.footerlog}>
            <InstagramIcon className={Styles.footerlog1} />
            <FacebookIcon className={Styles.footerlog2} />
            <YouTubeIcon className={Styles.footerlog3} />
          </div>
        </div>
      </div>
      <div className={Styles.footermain3}>
        <p>Copyrights © All rights reserved Tekkrinnovations pvt ltd</p>
      </div>
    </div>
  );
}

export default Footer;
