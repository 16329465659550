import React, { useEffect } from "react";
import NavBar from "../../NavBar/NavBar";
import { Parallax } from "react-parallax";
import styles from "./HomePage.module.css";
import drone from "../../../assets/drone.jpg";
import vedi from "../../../assets/vedio.mp4";
import services from "../../../assets/services.jpg";
import TypeWriterEffect from "react-typewriter-effect";
import { motion } from "framer-motion";
import Services from "../../Services/Services";
import Footer from "../../Footer/Footer";
import Partners from "../../Partners/Partners";
import Aos from "aos";
import "aos/dist/aos.css";
import droneone from "../../../assets/droneone.jpg";

export default function HomePage() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Parallax strength={100}>
        <div style={{ height: "100vh" }}>
          <div className={styles.pnvmain}>
            <div className={styles.contentvideo}>
              <video src={vedi} autoPlay muted loop />
            </div>
            <div className={styles.contentvideoshadw}></div>

            <div className={styles.content}>
              <div className={styles.textContentmain}>
                <div className={styles.textContent}>TekkrInnovations</div>
                <p data-aos="fade-down">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Parallax>

      <Parallax strength={100} blur={{ min: -5, max: 8 }} bgImage={droneone}>
        <div className={styles.content2}>
          <Services />
        </div>
      </Parallax>
      <Parallax strength={100} blur={{ min: -5, max: 10 }}>
        <div className={styles.content2}>
          <Partners />
        </div>
      </Parallax>

      <Footer style={{ backgroundColor: "#2d2d2d" }} />
    </motion.div>
  );
}
