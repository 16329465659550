import React from "react";
import styles from "./DelveryDroneSub.module.css";

export default function DelveryDroneSub() {
  return (
    <div className={styles.DelveryDroneSub}>
      <div className={styles.DelveryDroneSub2}>
        <div className={styles.DelveryDpointmiandiv}>
          <div className={styles.DelveryDpoint1h2}>
            <p>TI-250</p>
            <h2>Our heavy-lift solution</h2>
          </div>
          <div className={styles.DelveryDpoint1p}>
            <p>
              The TI-250 is our uncrewed, fully electric utility drone capable
              of carrying an impressive – and unprecedented – payload. While
              there are many design overlaps with the AirArk, we created the
              TI-250 to offer heavy-lift services to a slew of industries, and
              it will be deployed where classic transportation modes reach their
              limits.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
