import React from "react";
import styles from "./AboutUs.module.css";

import aboutus from "../../../assets/aboutus2.json";
import Lottie from "react-lottie";
import "aos/dist/aos.css";
import Footer from "../../Footer/Footer";

export default function AboutUs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aboutus,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={styles.aboutusmaindiv}>
      <div className={styles.aboutusmaindiv2}>
        <div className={styles.aboutusmaindivdrone}>
          <Lottie
            options={defaultOptions}
            loop={false}
            style={{ color: "red" }}
          />
        </div>
        <div className={styles.aboutusmaindivcontent}>
          <h2>About Us</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat
          </p>
          <p>
            voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum
          </p>
        </div>
        <div className={styles.aboutusmaindivdrone2}>
          <Lottie
            options={defaultOptions}
            loop={false}
            style={{ color: "red" }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
