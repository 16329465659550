import React from "react";
import styles from "./ThreeWheeler.module.css";
import threewheelerr from "../../assets/threewheelerr.png";
import Tilt from "react-parallax-tilt";

import electriccar from "../../assets/electriccar.json";
import Lottie from "react-lottie";
import Aos from "aos";
import "aos/dist/aos.css";
import Graph from "../Graph/Graph";
import EfficentCargoPoints from "../EfficentCargoPoints/EfficentCargoPoints";

export default function ThreeWheeler() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: electriccar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={styles.maindivdiv}>
      <div className={styles.maindiv}>
        <div className={styles.maindiv2}>
          <Tilt
            glareEnable={true}
            tiltMaxAngleX={10}
            tiltMaxAngleY={10}
            perspective={1000}
            glareColor={"rgb(255,0,0)"}
            div
            className={styles.maindivimgg}
          >
            <div className={styles.maindivimg}>
              {/* <img src={threewheelerr} alt="threewheelerr" /> */}
              <Lottie
                options={defaultOptions}
                // height={80}
                // width={80}
                loop={false}
                style={{ color: "red" }}
              />
            </div>
          </Tilt>
          <div className={styles.maindivcontent}>
            <h2>URBAN ELECTRIC VEHICLES</h2>
            <p>
              TARS is our answer to the critical need for electric delivery
              vehicles for cities. It is optimised for last-mile deliveries in
              urban environments, able to carry anything from pallets and
              packages to post and food. For things that need to be moved in
              cities, with minimal impact.
            </p>
            <p>
              TARS takes energy efficiency to a new level by having the lightest
              vehicle weight to cargo volume on the market. Thereby maximising
              utilisation while minimising energy consumption over time. Its
              solar panel roof, connectivity enabled services, and flexible
              cargo space are some of its key benefits. Carefully developed
              based on market needs.
            </p>
          </div>
        </div>
      </div>
      {/* <div className={styles.line}></div>{" "} */}
      <div className={styles.threewheelerrspeci}>
        <div className={styles.threewheelerrspeci2}>
          <div className={styles.threewheelerrspecih2}>
            <h2>Key specifications</h2>
          </div>
          <div className={styles.threewheelerrspecicnt}>
            <div className={styles.threewheelerrspecicnt2}>
              <div className={styles.threewheelerrspecicnt2max}>
                <p>MAX SPEED</p>
              </div>
              <div className={styles.threewheelerrspecicnt2sp}>
                <span>60</span>
                <p>km/h</p>
              </div>
            </div>
            <div className={styles.threewheelerrspecicnt2}>
              <div className={styles.threewheelerrspecicnt2max}>
                <p>CARGO SPACE</p>
              </div>
              <div className={styles.threewheelerrspecicnt2sp}>
                <span>2.5</span>
                <p>M3</p>
              </div>
            </div>
            <div className={styles.threewheelerrspecicnt2}>
              <div className={styles.threewheelerrspecicnt2max}>
                <p>CARGO WEIGHT</p>
              </div>
              <div className={styles.threewheelerrspecicnt2sp}>
                <span>350</span>
                <p>kg</p>
              </div>
            </div>
          </div>
          <div className={styles.threewheelerrspecicnt22}>
            <div className={styles.threewheelerrspecicnt21}>
              <div className={styles.threewheelerrspecicnt2max}>
                <p>SOLAR POWER</p>
              </div>
              <div className={styles.threewheelerrspecicnt2sp}>
                <span>570</span>
                <p>W</p>
              </div>
            </div>
            <div className={styles.threewheelerrspecicnt21}>
              <div className={styles.threewheelerrspecicnt2max}>
                <p>RANGE</p>
              </div>
              <div className={styles.threewheelerrspecicnt2sp}>
                <span>100/200</span>
                <p>km</p>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className={styles.purchasingmaindivmain}>
        <div className={styles.line}>
          <h2>Cost</h2>
        </div>
        <div className={styles.purchasingmaindiv12}>
          <div className={styles.purchasingmaindiv}>
            <div className={styles.purchasingmaindiv2}>
              <div className={styles.purchasingmaindiv1}>
                <h2>1/3</h2>
              </div>
              <div className={styles.purchasingmaindiv2}>
                <p>PURCHASING COST</p>
              </div>
              <div className={styles.purchasingmaindiv3}>
                <p>
                  {" "}
                  EVIG is a third of the purchasing cost of an equivalent
                  conventional electric vehicle. Helping you scale your business
                  in a financiall sustainble way.
                </p>
              </div>
            </div>
            <div className={styles.purchasingmaindiv2}>
              <div className={styles.purchasingmaindiv1}>
                <h2>1/12</h2>
              </div>
              <div className={styles.purchasingmaindiv2}>
                <p>RUNNING COST</p>
              </div>
              <div className={styles.purchasingmaindiv3}>
                <p>
                  {" "}
                  Running cost is based on average cost of maintenance,
                  operations, congestion fees(Sweden), charging infrastructure
                  and energy per km. Given that EVIG requires no charging
                  infrastructure with minimal maintenance and energy it has
                  roughly 1 twelfth of an equivalent conventional vehicle's
                  running cost.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line}></div> <Graph />
      <div className={styles.line}></div> <EfficentCargoPoints />
    </div>
  );
}
