import React from "react";
import styles from "./TheDroneAndOnly.module.css";
import droneicon from "../../assets/droneicon.png";
import route from "../../assets/route.png";
import leaves from "../../assets/leaves.png";

export default function TheDroneAndOnly() {
  return (
    <div className={styles.thedroneandonlydiv}>
      <div className={styles.thedroneandonlydiv2}>
        <div className={styles.thedroneandonlyh2p}>
          <div className={styles.thedroneandonlyh2}>
            <h2>THE DRONE AND ONLY</h2>
          </div>
          <div className={styles.thedroneandonlyp}>
            <p>Winning features</p>
          </div>
        </div>
        <div className={styles.thedroneandonlycontent}>
          <div className={styles.thedroneandonlycontent2}>
            <div className={styles.thedroneandonlyicon}>
              <img src={droneicon} alt="leaves" />
            </div>
            <div className={styles.thedroneandonlyh2h}>
              <h2>Seriously strong</h2>
            </div>
            <div className={styles.thedroneandonlyh2p}>
              <p>
                Our TI-250 will be able to carry a payload of up to 200 kg. This
                makes them suitable for a wide range of heavy-duty tasks. A real
                asset to our UAM arsenal .
              </p>
            </div>
          </div>
          <div className={styles.thedroneandonlycontent2}>
            <div className={styles.thedroneandonlyicon}>
              {" "}
              <img src={route} alt="leaves" />
            </div>
            <div className={styles.thedroneandonlyh2h}>
              {" "}
              <h2>Far-reaching</h2>
            </div>
            <div className={styles.thedroneandonlyh2p}>
              <p>
                The TI-250 will boast a range of up to 40 kilometers, meaning
                they'll easily operate within a large radius. And given their
                immense payload, this opens up a world of posibilities.
              </p>
            </div>
          </div>
          <div className={styles.thedroneandonlycontent2}>
            <div className={styles.thedroneandonlyicon}>
              <img src={leaves} alt="leaves" />
            </div>
            <div className={styles.thedroneandonlyh2h}>
              {" "}
              <h2>Fully electric</h2>
            </div>
            <div className={styles.thedroneandonlyh2p}>
              <p>
                Just like our TI-250 air taxis, the TI-250 is all electric and
                emission-free in flight. Clean and quiet,it's and ideal means of
                transportation. And that's just the tip of our sustainability
                iceberg.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
