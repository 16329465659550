import React, { useEffect } from "react";
import Tilt from "react-parallax-tilt";
import styles from "./Services.module.css";
import drn from "../../assets/dronee.png";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Services() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    // <div className={styles.top}>
    //   <div className={styles.perspective}>
    //     <div className={styles.card}>
    //       <div className={styles.thumb}>
    //         <h2>Consulting</h2>
    //         <span>Consulting</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={styles.service}>
      <div className={styles.serviceh2}>
        <h2>Our Services</h2>
      </div>
      <div className={styles.servicesmaindiv}>
        <Tilt
          glareEnable={true}
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          glareColor={"rgb(255,0,0)"}
        >
          <div className={styles.card} data-aos="fade-left">
            <div className={styles.thumb}>
              <h2>Consulting</h2>
              <div className={styles.thumb2shw}></div>
            </div>
            <div className={styles.contentdetails}>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Lorem ipsum dolor sit amet.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Consectetur adipiscing elit.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Lorem ipsum dolor sit amet.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Consectetur adipiscing elit.</p>
              </div>
            </div>
          </div>
        </Tilt>
        <Tilt
          glareEnable={true}
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          glareColor={"rgb(255,0,0)"}
        >
          <div className={styles.card} data-aos="fade-right">
            <div className={styles.thumb2}>
              <h2>Engineering</h2>
              <div className={styles.thumb2shw}></div>
            </div>
            <div className={styles.contentdetails}>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Lorem ipsum dolor sit amet.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Consectetur adipiscing elit.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Lorem ipsum dolor sit amet.</p>
              </div>
              <div className={styles.contentdetails2}>
                <img src={drn} alt="." />
                <p>Consectetur adipiscing elit.</p>
              </div>
            </div>
          </div>
        </Tilt>
      </div>
    </div>
  );
}
