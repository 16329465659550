import React from "react";
import Styles from "./ContactUsPage.module.css";
import passengersdrone from "../../assets/passengersdrone.png";
import Tilt from "react-parallax-tilt";

import contactus from "../../assets/contactus.json";
import Lottie from "react-lottie";
import Aos from "aos";
import "aos/dist/aos.css";

import MailIcon from "@mui/icons-material/Mail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessIcon from "@mui/icons-material/Business";
import Footer from "../Footer/Footer";

export default function ContactUsPage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contactus,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div id="contactus" className={Styles.ContactUsmain}>
      <div className={Styles.ContactUsmain22}>
        <div className={Styles.ContactUsmaindiv}>
          <div className={Styles.ContactUsmaindiv2}>
            <h4>Get In Touch</h4>
            <p>
              <MailIcon className={Styles.iconn} />
              info@tekkrexim.in
            </p>
            <p>
              <PhoneAndroidIcon className={Styles.iconn} />
              9381280691
            </p>
            <p>
              <BusinessIcon className={Styles.iconn} />
              8-2-248/A/5/30 & 31 Sri, Banjara Hills Rd Number 3, North H Block,
              Journalist Colony, Venkateshwara Hills, Banjara Hills, Telangana
              500034
            </p>
          </div>
        </div>
        <div className={Styles.ContactUsmaindiv3}>
          <Lottie
            options={defaultOptions}
            // height={80}
            // width={80}
            loop={false}
            style={{ color: "red" }}
          />
        </div>
      </div>
      <Footer style={{ backgroundColor: "transparent" }} />
    </div>
  );
}
