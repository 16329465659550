import React from "react";
import styles from "./DeliveryDrones.module.css";
import delvrydrones from "../../assets/delvrydrones.png";
import Tilt from "react-parallax-tilt";

import threew from "../../assets/threew.json";
import Lottie from "react-lottie";
import Aos from "aos";
import "aos/dist/aos.css";
import DelveryDroneSub from "../DelveryDroneSub/DelveryDroneSub";
import TheDroneAndOnly from "../TheDroneAndOnly/TheDroneAndOnly";
import LogisticsInfra from "../LogisticsInfra/LogisticsInfra";

export default function DeliveryDrones() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: threew,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={styles.maindiv}>
      <div className={styles.maindiv2}>
        <div className={styles.maindivcontent}>
          <h2>Delivery Drones</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
          </p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
          </p>
        </div>
        <Tilt
          glareEnable={true}
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          glareColor={"rgb(255,0,0)"}
        >
          <div className={styles.maindivimg}>
            {/* <img src={delvrydrones} alt="delvrydrones" /> */}
            <Lottie
              options={defaultOptions}
              // height={80}
              // width={80}
              loop={false}
              style={{ color: "red" }}
            />
          </div>
        </Tilt>
      </div>
      <div>
        <DelveryDroneSub />
      </div>
      <div>
        <TheDroneAndOnly />
      </div>
      <div className={styles.line}></div>
      <div>
        <LogisticsInfra />
      </div>
    </div>
  );
}
