import React, { useState } from "react";
import styles from "./ProductPage.module.css";
import cx from "classnames";
import ThreeWheeler from "../ThreeWheeler/ThreeWheeler";
import DeliveryDrones from "../DeliveryDrones/DeliveryDrones";
import PassengerDrones from "../PassengerDrones/PassengerDrones";
import Footer from "../Footer/Footer";

export default function ProductPage() {
  const [showTrue, setShowTrue] = useState("show");

  const showTrueFn = (e) => {
    setShowTrue(e);
    console.log("e", e);
  };

  return (
    <div className={styles.productpagemain}>
      <div className={styles.productpagemain2}>
        <div className={styles.productpagemainh2}>
          <h2>Products</h2>
        </div>
        <div className={styles.productpagemainbtns}>
          <button
            onClick={() => {
              showTrueFn("show");
            }}
            className={cx(
              `${styles.changeclr} ${
                showTrue === "show" ? [styles.showclr] : [styles.hideclr]
              }`
            )}
          >
            {/* 3 wheelers ( auto ) */}URBAN ELECTRIC VEHICLES
          </button>
          <button
            onClick={() => {
              showTrueFn("show2");
            }}
            className={cx(
              `${styles.changeclr} ${
                showTrue === "show2" ? [styles.showclr] : [styles.hideclr]
              }`
            )}
          >
            DELIVERY DRONES
          </button>
          <button
            onClick={() => {
              showTrueFn("show3");
            }}
            className={cx(
              `${styles.changeclr} ${
                showTrue === "show3" ? [styles.showclr] : [styles.hideclr]
              }`
            )}
          >
            eVTOL
          </button>
        </div>
        <div>
          {showTrue === "show" ? (
            <ThreeWheeler />
          ) : showTrue === "show2" ? (
            <DeliveryDrones />
          ) : (
            <PassengerDrones />
          )}
        </div>
      </div>
      <Footer style={{ backgroundColor: "transparent" }} />
    </div>
  );
}
