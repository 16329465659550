import React from "react";
import styles from "./LogisticsInfra.module.css";

export default function LogisticsInfra() {
  return (
    <div className={styles.logisticsmaindiv}>
      <div className={styles.logisticsmaindivvv}>
        <div className={styles.logisticsmaindiv2}>
          <div className={styles.logisticsmaindiv21}>
            <div className={styles.logisticscontent}>
              <div className={styles.logisticscontenth2}>
                <h2>Logistics</h2>
              </div>
              <div className={styles.logisticscontentp}>
                <p>
                  From retail to spare parts deliveries the TI-250 will deliver
                  your package safely, efficiently, and on time. The deal
                  solution for heavy-duty logistics requirements
                </p>
              </div>
            </div>
            <div className={styles.logisticscontent}>
              <div className={styles.logisticscontenth2}>
                <h2>Infrastructure</h2>
              </div>
              <div className={styles.logisticscontentp}>
                <p>
                  The TI-250 will also assist with daily operations, like
                  building sites. its high payload makes it deal for tasks in
                  construction maintainace, and site planning
                </p>
              </div>
            </div>
          </div>
          <div className={styles.logisticsmaindiv21}>
            <div className={styles.logisticscontent}>
              <div className={styles.logisticscontenth2}>
                <h2>Public services</h2>
              </div>
              <div className={styles.logisticscontentp}>
                <p>
                  In moments of need, the TI-250 will be quickly deployed to
                  provide disaster relief, air rescue, or support humanitarian
                  aid efforts
                </p>
              </div>
            </div>
            <div className={styles.logisticscontent}>
              <div className={styles.logisticscontenth2}>
                <h2>Store to ship</h2>
              </div>
              <div className={styles.logisticscontentp}>
                <p>
                  The TI-250 will also offer top-class shore-to-ship deliveries,
                  transporting goods like spare parts. This will step up the
                  overall efficiency of the maritime value chain - without any
                  direct emission whatsoever
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
