import React, { useEffect } from "react";
import styles from "./Partners.module.css";
import partners from "../../assets/partners.png";
import airark from "../../assets/airarkwhite.png";
import dremotore from "../../assets/dremotore.png";
import farm29 from "../../assets/farm29.png";
import flypigeon from "../../assets/flypigeonlogo.png";

import Aos from "aos";
import "aos/dist/aos.css";

export default function Partners() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={styles.partnners}>
      <div className={styles.partnners2}>
        <div className={styles.partnnersbg}>
          <img src={partners} alt="partners " />
        </div>
        <div className={styles.partnnersbgshd}></div>
        <div className={styles.partnnersh2clinet}>
          <div className={styles.partnnersh2}>
            <h2>Our Clients</h2>
          </div>
          <div className={styles.partnnersclients} data-aos="zoom-out">
            <div className={styles.partnnersclients22}>
              <img
                className={styles.partnnersclients21}
                src={airark}
                alt="airark"
              />
              <img
                className={styles.partnnersclients2}
                src={dremotore}
                alt="eda"
              />
            </div>
            <div className={styles.partnnersclients22}>
              <img
                className={styles.partnnersclients1}
                src={farm29}
                alt="farm29"
              />
              <img
                className={styles.partnnersclients2}
                src={flypigeon}
                alt="flypigeon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
