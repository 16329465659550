import React from "react";
import styles from "./Products.module.css";
import { motion } from "framer-motion";
import NavBar from "../NavBar/NavBar";
import bag from "../../assets/bag.png";
import bag2 from "../../assets/bag2.png";
import ParticalBackground from "../ParticalBackground/ParticalBackground";
import { Parallax } from "react-parallax";
import ProductPage from "../ProductPage/ProductPage";

export default function Products() {
  return (
    <motion.div
      className={styles.pnvmain}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ParticalBackground></ParticalBackground>
      <div className={styles.prodtpage}>
        <ProductPage />
      </div>
    </motion.div>
  );
}
