import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "../Components/Home/HomePage/HomePage";
import Services from "../Components/Services/Services";
import { AnimatePresence } from "framer-motion";
import NavBar from "./NavBar/NavBar";
import styles from "./AnimatedRoutes.module.css";
import Products from "./Products/Products";
import Contactus from "./Contactus/Contactus";
import AboutUsPageParticals from "./AboutUsPage/AboutUsPageParticals/AboutUsPageParticals";
import Footer from "./Footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition/TermsAndCondition";
import { matchRoutes } from "react-router-dom";

export default function AnimatedRoutes() {
  const location = useLocation();
  // const routes = [{ path: "/members/:id" }];
  // useEffect(() => {
  //   console.log("ss", [{ path: "/members/:id" }]);
  // }, [routes]);

  return (
    <AnimatePresence>
      <div className={styles.navbbmain}>
        <div className={styles.navbb}>
          {/* {routes} */}
          <NavBar />
        </div>
        <div className={styles.navbbrouts}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/aboutus" element={<AboutUsPageParticals />} />
            <Route
              path="/privacypolicy"
              element={<PrivacyPolicy style={{ backgroundColor: "#2d2d2d" }} />}
            />
            <Route
              path="/termsandconditions"
              element={
                <TermsAndCondition style={{ backgroundColor: "#2d2d2d" }} />
              }
            />
          </Routes>
        </div>
      </div>
    </AnimatePresence>
  );
}
