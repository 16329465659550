import React from "react";
import styles from "./Contactus.module.css";
import ParticalBackground from "../ParticalBackground/ParticalBackground";
import { motion } from "framer-motion";
import ContactUsPage from "../ContactUsPage/ContactUsPage";

export default function Contactus() {
  return (
    <motion.div
      className={styles.pnvmain}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ParticalBackground></ParticalBackground>
      <div className={styles.prodtpage}>
        <ContactUsPage />
      </div>
    </motion.div>
  );
}
